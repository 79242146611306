var calendar = function (selector) {

    var calendar = this;

    this.objCalendar = selector;
    this.enablePopover = true;

    this.bindEvents = function () {
        calendar.initCalendar();
    };

    this.initCalendar = function () {
        /* initialize the calendar */

        calendar.objCalendar.fullCalendar({
            defaultView: 'timelineWeek',
            slotDuration: "24:00:00",
            minTime: "00:00:00",
            maxTime: "24:00:00",
            height: 'auto',
            stickyHeaderDates: 'auto',
            firstDay: 6,
            weekStart: 6,
            editable: true,
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'timelineWeek,timelineMonth,timelineYear'
            },
            buttonHtml: {
                prev: '<i class="ace-icon fa fa-chevron-left"></i>',
                next: '<i class="ace-icon fa fa-chevron-right"></i>'
            },
            resourceLabelText: '',
            eventDurationEditable: false,
            resources: function (callback) {
                var params = {
                    ticketId: $("#ticket_id").val(),
                    customerId: $("#customer_filter").val(),
                    departmentId: $("#department_filter").val(),
                    teamId: $("#team_filter").val(),
                    technicianId: $("#technician_filter").val(),
                    eventTypeId: $("#event_type_filter").val()
                };

                $.get('scheduler/calendar_resources', params, function (data) {
                    calendar.calendar_resources = data;
                    callback(data);
                });
            },
            events: {
                url: 'scheduler/calendar_data',
                type: 'GET',
                data:
                    function () {
                        return {
                            ticketId: $("#ticket_id").val(),
                            customerId: $("#customer_filter").val(),
                            departmentId: $("#department_filter").val(),
                            teamId: $("#team_filter").val(),
                            technicianId: $("#technician_filter").val(),
                            eventTypeId: $("#event_type_filter").val()
                        };
                    },
                error: function () {
                    app.error('Error');
                },
            },
            eventRender: function (event, element, a) {
                element.find('.fc-content').html(event.html);
            },
            eventClick: function (calEvent, jsEvent, view) {
                if(calEvent.ticketUrl !== '') {
                    window.location.href = app.baseUrl + '/' + calEvent.ticketUrl;
                } else {
                    app.block(1);
                    $.get(app.baseUrl + '/scheduler/' + calEvent.id + '/edit')
                        .done(function (data) {
                            app.block(0);

                            $("#modalEditEvent .modal-body").html(data);

                            $('#modalEditEvent').modal('show');

                            $('#modalEditEvent').on('hidden.bs.modal', function () {
                                calendar.objCalendar.fullCalendar('removeEvents');
                                calendar.objCalendar.fullCalendar('removeResources');
                                calendar.objCalendar.fullCalendar('refetchEvents');
                                calendar.objCalendar.fullCalendar('refetchResources');
                            });

                            $('#modalEditEvent [data-interaction="modal-delete"]').css('display', 'inline');

                            $('#modalEditEvent [data-interaction="modal-delete"]').unbind('click').bind('click', function () {
                                if(!confirm('Are you sure you want to continue?')) {
                                    return;
                                }

                                $.delete(app.baseUrl + "/scheduler/" + calEvent.id)
                                    .done(function (data) {
                                        if(data.response) {
                                            app.success('Updated succesfully');

                                            $("#modalEditEvent [data-dismiss=modal]").trigger('click');
                                        } else {
                                            app.warning("", data.message);
                                        }
                                    })
                                    .fail(function () {
                                        app.error("", "Errore Ajax!");
                                    });
                            });

                            $('#modalEditEvent [data-interaction="modal-save"]').unbind('click').bind('click', function () {
                                var data = lib.formSerialize($("#frmScheduler"));

                                app.block(1);
                                $.put(app.baseUrl + '/scheduler/' + calEvent.id, data)
                                    .done(function (data) {
                                        if (data.response) {
                                            app.success('Updated succesfully');

                                            $("#modalEditEvent [data-dismiss=modal]").trigger('click');
                                        } else {
                                            app.warning("", data.message);
                                        }
                                        app.block(0);
                                    })
                                    .fail(function () {
                                        console.error("Errore Ajax!");
                                        app.error("", "Errore Ajax!");
                                    });
                            });
                        })
                        .fail(function (data) {
                            app.block(0);
                            app.error("", "Errore AJAX!");
                        });
                }
            },
            eventDrop: function(event, delta, revertFunc, jsEvent, ui, view){
                if (!confirm("Are you sure?")) {
                    calendar.objCalendar.fullCalendar('removeEvents');
                    calendar.objCalendar.fullCalendar('removeResources');
                    calendar.objCalendar.fullCalendar('refetchEvents');
                    calendar.objCalendar.fullCalendar('refetchResources');

                    return;
                }
                $.post(app.baseUrl + "/scheduler/update_scheduler_dates", {
                    id_event: event.id,
                    id_resource: event.resourceId,
                    start: event.start.format("YYYY-MM-DD"),
                    end: event.end.format("YYYY-MM-DD")
                })
                    .done(function (data) {
                        if(data.response) {
                            app.success('Updated succesfully');
                        } else {
                            app.warning("", data.message);
                            calendar.objCalendar.fullCalendar('removeEvents');
                            calendar.objCalendar.fullCalendar('removeResources');
                            calendar.objCalendar.fullCalendar('refetchEvents');
                            calendar.objCalendar.fullCalendar('refetchResources');
                        }
                    })
                    .fail(function () {
                        app.error("", "Errore Ajax!");
                    });
            },
        });
    };
    calendar.bindEvents();
};
